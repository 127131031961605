<template>
  <Component :is="tag">
    <input
      v-if="!oneAlwaysActive"
      :id="deselectId"
      class="visually-hidden"
      type="radio"
      :name="groupId"
      @change="activeItem = deselectId"
    />
    <input
      :id="id"
      class="input visually-hidden"
      type="radio"
      :name="groupId"
      :checked="isActive"
      @change="handleChange"
    />
    <slot :is-active="isActive" :collapse-id="id" />
  </Component>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string
  tag: keyof HTMLElementTagNameMap
  active?: boolean
}>()

const emit = defineEmits(['change'])

const groupId = inject<string>('group-id')
const deselectId = `deselect_${groupId}_${props.id}`

const activeItem = useRequiredInject<Ref<string>>('active-item')
const oneAlwaysActive = inject<boolean>('one-always-active')
const isActive = computed(() => activeItem.value === props.id)

const handleChange = () => {
  activeItem.value = props.id
  emit('change')
}

const labelId = computed(() => {
  if (!oneAlwaysActive && isActive.value) return deselectId
  return props.id
})

// eslint-disable-next-line vue/no-setup-props-destructure
if (props.active) activeItem.value = props.id

watch(
  () => props.active,
  value => {
    if (value) activeItem.value = props.id
  },
  { immediate: true }
)

provide('collapse-id', labelId)
provide('collapse-active', isActive.value)
</script>
