<template>
  <div class="collapse-content">
    <div class="collapse-content__inner">
      <slot :is-active="isActive" />
    </div>
  </div>
</template>

<script setup lang="ts">
const isActive = inject<boolean>('collapse-active')
</script>

<style scoped lang="scss">
.collapse-content {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows $transition,
    opacity 0.2s 0.2s;

  &__inner {
    overflow: hidden;
  }
}
</style>
