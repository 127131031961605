<template>
  <Component :is="tag">
    <input
      :id="id"
      class="input visually-hidden"
      type="checkbox"
      :checked="isActive"
      @change="handleChange"
    />
    <slot :is-active="isActive" :collapse-id="id" />
  </Component>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string
  tag: keyof HTMLElementTagNameMap
  initialActive?: boolean
}>()

const isActive = ref(props.initialActive)

const handleChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  isActive.value = input.checked
}

provide('collapse-id', props.id)
provide('collapse-active', isActive.value)
</script>
