<template>
  <CollapseRadio
    v-if="groupId"
    :id="id"
    :tag="tag"
    :active="active"
    @change="$emit('change')"
  >
    <slot />
  </CollapseRadio>
  <CollapseCheckbox v-else :id="id" :tag="tag" :initial-active="initialActive">
    <slot />
  </CollapseCheckbox>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    // need to use id from props because `useId` is broken
    // https://github.com/nuxt/nuxt/issues/27378
    id: string
    tag?: keyof HTMLElementTagNameMap
    initialActive?: boolean
    active?: boolean
  }>(),
  {
    tag: 'div'
  }
)

defineEmits(['change'])

const groupId = inject<string | null>('group-id', null)
</script>

<style lang="scss" scoped>
:deep(.input:checked ~ .collapse-content) {
  @include show-collapse-content;
}
</style>
